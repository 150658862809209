import { useState } from "react";

const RetakeTest = () => {
  const [showModal, setShowModal] = useState(false);
  const handleButtonClick = () => {
    setShowModal(true);
    // set timeout
    setTimeout(() => {
      setShowModal(false);
    }, 3000);
  };

  const handleExitModal = (e) => {
    if (e.target.classList.contains("bg-[#000000cf]")) {
      setShowModal(false);
    }
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        className="bg-secondary text-white font-bold px-3 py-2 rounded-md"
      >
        RETAKE TEST
      </button>
      {showModal && (
        <div
          onClick={handleExitModal}
          className="fixed left-0 top-0 h-screen w-screen bg-[#000000cf] flex justify-center items-center"
        >
          <div className="bg-white p-5 rounded-md">
            <p>
              Please contact your manager if you wish to retake your personality
              test.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default RetakeTest;
