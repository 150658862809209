export const calculateMBTI = ({ answers }) => {
  const dimensions = {
    E: 0,
    I: 0, // Energy
    N: 0,
    S: 0, // Mind
    T: 0,
    F: 0, // Nature
    J: 0,
    P: 0, // Tactics
  };

  // Evaluate answers (answers should be an array of 60 values ranging from -3 to 3)
  for (let i = 0; i < answers.length; i++) {
    const score = answers[i];

    if (i % 4 === 0) {
      dimensions[score > 0 ? "E" : "I"] += Math.abs(score);
    } else if (i % 4 === 1) {
      dimensions[score > 0 ? "N" : "S"] += Math.abs(score);
    } else if (i % 4 === 2) {
      dimensions[score > 0 ? "T" : "F"] += Math.abs(score);
    } else {
      dimensions[score > 0 ? "J" : "P"] += Math.abs(score);
    }
  }

  // Determine the dominant trait for each dimension
  const personalityCode = `${dimensions.E > dimensions.I ? "E" : "I"}${
    dimensions.N > dimensions.S ? "N" : "S"
  }${dimensions.T > dimensions.F ? "T" : "F"}${
    dimensions.J > dimensions.P ? "J" : "P"
  }`;

  return {
    code: personalityCode,
    scores: dimensions,
  };
};
