import checks from "assets/images/backgrounds/checks.png";
import downloadIcon from "assets/images/icons/download.png";
import CopyReferenceCode from "components/CopyReferenceCode";
import DaysLeft from "components/DaysLeft";
import Profile from "components/Profile";
import TopMenu from "components/TopMenu";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const overlayStyle = {
  backgroundPosition: "center center",
  borderRadius: "50px 50px 35px 35px",
  backgroundColor: "#fff",
  backgroundImage: `url(${checks})`,
};

const Lock = (
  <div className="absolute -right-1 translate-x-full">
    <div className="w-[10px] h-[8px] bg-[#9d9d9d] rounded-[1px] relative before:content-['']  before:w-[6px] before:h-[4px] before:border-[2px] before:border-solid before:border-[#9d9d9d] before:border-b-0 before:rounded-t-[10px] before:rounded-[10px_10px_0_0] before:absolute before:top-[-2px] before:left-1/2 before:-translate-x-1/2 before:translate-y-[-50%] before:bg-transparent"></div>
  </div>
);

const Journey = () => {
  const { profile, setProfile } = useContext(AuthContext);
  const [currentStep, setCurrentStep] = useState(profile.journeyStep ?? 1);

  useLayoutConfig("purple", true);
  useDocumentTitle("Muskurahat Foundation | Explore");
  const navigate = useNavigate();

  const [step2Checks, setStep2Checks] = useState({
    step2Check1: false,
    step2Check2: false,
    step2Check3: false,
    step2Check4: false,
    step2Check5: false,
  });

  useEffect(() => {
    if (profile.journeyStep) setCurrentStep(profile.journeyStep);
  }, [profile.journeyStep]);

  useEffect(() => {
    if (
      step2Checks.step2Check1 &&
      step2Checks.step2Check2 &&
      step2Checks.step2Check3 &&
      step2Checks.step2Check4 &&
      step2Checks.step2Check5
    ) {
      setProfile({ ...profile, journeyStep: 3 });
    }
  }, [step2Checks]);

  const steps = {
    1: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055123771?h=f9f72ede7e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 01"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>
          <h2 className="text-xs uppercase font-bold">
            Understanding Crowdfunding & Your Role:
          </h2>
          <p className="text-xs leading-[15px]">
            We have broken down a list of tasks for you to finish in the next
            couple of days. This will help you slowly & steadily understand how
            you are suppose to go about the internship.
          </p>
          <h2 className="text-primary text-base font-bold mt-5">
            Understand Your Role
          </h2>
          <p className="text-xs leading-[15px]">
            Fill out the Crowdfunding 101 form below to learn more about your
            role and what this internship is all about. It&apos;s super
            important to go through this information to get a clear picture of
            how you&apos;ll contribute.
          </p>
          <button
            className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            onClick={() => {
              var win = window.open(
                "https://muskurahat-forms.web.app/fundamentals-of-crowdfunding",
                "_blank",
              );
              win.focus();
            }}
          >
            Crowdfunding 101
          </button>
          <h2 className="text-secondary text-base font-bold mt-5">
            Learn About Our Cause
          </h2>
          <p className="text-xs leading-[15px]">
            Take a moment to go through the attached PDF to dive deeper into
            Muskurahat&apos;s mission and the cause we&apos;re working for.
          </p>
          <button
            className="text-white bg-secondary font-bold px-3 py-2 mt-5 rounded-lg flex gap-3 justify-center items-center"
            onClick={() => {
              var win = window.open(
                "https://www.muskurahat.org.in/resources/documents/about%20the%20cause.pdf",
                "_blank",
              );
              win.focus();
            }}
          >
            <span>About Our Cause</span>
            <span className="bg-tertiary p-2 rounded-full flex justify-center items-center">
              <img src={downloadIcon} className="w-3 h-3" />
            </span>
          </button>
          <div className="mt-5 flex gap-[4px] text-xs">
            <input
              disabled={profile.journeyStep > 1}
              defaultChecked={profile.journeyStep > 1}
              type="checkbox"
              onChange={() => {
                setProfile({ ...profile, journeyStep: 2 });
              }}
              id="step-1"
            />
            <label htmlFor="step-1">
              I have finished my tasks for the day.
            </label>
          </div>
        </div>
      </>
    ),
    2: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055160436?h=18afabcac0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 02"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <p className="text-xs leading-[15px]">
            Here&apos;s a quick summary on the checklist you need to fulfil as
            we proceed with the internship. Tick the boxes if you understand all
            the requirements.
          </p>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 2}
              defaultChecked={profile.journeyStep > 2}
              type="checkbox"
              onChange={() => {
                setStep2Checks({
                  ...step2Checks,
                  step2Check1: !step2Checks.step2Check1,
                });
              }}
              id="step-2-1"
            />
            <label className="text-secondary" htmlFor="step-2-1">
              I need to finish my orientation
            </label>
          </div>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 2}
              defaultChecked={profile.journeyStep > 2}
              type="checkbox"
              onChange={() => {
                setStep2Checks({
                  ...step2Checks,
                  step2Check2: !step2Checks.step2Check2,
                });
              }}
              id="step-2-2"
            />
            <label className="text-secondary" htmlFor="step-2-2">
              I understand there will be 3-4 Meetings Throughout The Journey
            </label>
          </div>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 2}
              defaultChecked={profile.journeyStep > 2}
              type="checkbox"
              onChange={() => {
                setStep2Checks({
                  ...step2Checks,
                  step2Check3: !step2Checks.step2Check3,
                });
              }}
              id="step-2-3"
            />
            <label className="text-secondary" htmlFor="step-2-3">
              I will be required to send an update about internship once every
              week
            </label>
          </div>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 2}
              defaultChecked={profile.journeyStep > 2}
              type="checkbox"
              onChange={() => {
                setStep2Checks({
                  ...step2Checks,
                  step2Check4: !step2Checks.step2Check4,
                });
              }}
              id="step-2-4"
            />
            <label className="text-secondary" htmlFor="step-2-4">
              I will read & acknowledge all messages I receive on Whatsapp
            </label>
          </div>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 2}
              defaultChecked={profile.journeyStep > 2}
              type="checkbox"
              onChange={() => {
                setStep2Checks({
                  ...step2Checks,
                  step2Check5: !step2Checks.step2Check5,
                });
              }}
              id="step-2-5"
            />
            <label className="text-secondary" htmlFor="step-2-5">
              I will follow the codes of the internship & I am ready for the
              next step
            </label>
          </div>
        </div>
      </>
    ),
    3: (
      <form autoComplete="off">
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055141265?h=df4c841e59&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 03"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <h2 className="text-primary text-base font-bold">
            A Quick Tour On How To Navigate Your Dashboard
          </h2>
          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 3}
              defaultChecked={profile.journeyStep > 3}
              type="checkbox"
              onChange={() => {
                setProfile({ ...profile, journeyStep: 4 });
              }}
              id="step-3"
            />
            <label className="text-secondary" htmlFor="step-3">
              I fully understand how to use my dashboard now
            </label>
          </div>
        </div>
      </form>
    ),
    4: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055176627?h=c101007ca1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 04"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <p className="text-xs leading-[15px]">
            Let the crowdfunding begin, here&apos;s what you need to do:
          </p>
          <ol className="text-secondary list-decimal text-xs leading-[15px] mt-5 font-medium px-5">
            <li className="mt-3">
              Run a quick recap on learning everything about our cause and
              Project Keytaab.
            </li>
            <li className="mt-2">
              Take this small tour to understand how you can kickstart your
              internship journey
            </li>
          </ol>
          <button
            className="text-white bg-primary font-bold px-3 py-2 mt-5 rounded-lg"
            onClick={() => {
              navigate("/user/start-here");
            }}
          >
            Kickstart My Internship
          </button>
          <p className="text-xs leading-[15px] mt-5">
            Once you&apos;ve started pitching the cause in your network, come
            back here and tick this box off
          </p>
        </div>
      </>
    ),
    5: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055179361?h=9e3d0f4a26&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 05"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <p className="text-xs leading-[15px]">
            Hope this video was helpful in understanding how to make your pitch
            effective. If you have any more doubts please feel free to connect
            with your reporting manager.
          </p>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 5}
              defaultChecked={profile.journeyStep > 5}
              type="checkbox"
              onChange={() => {
                setProfile({ ...profile, journeyStep: 6 });
              }}
              id="step-5"
            />
            <label className="text-secondary" htmlFor="step-5">
              I understand how to make a pitch to a donor
            </label>
          </div>
        </div>
      </>
    ),
    6: (
      <>
        <div>
          <div className="py-10">
            <div style={{ padding: "177.78% 0 0 0", position: "relative" }}>
              <iframe
                className="rounded-2xl"
                src="https://player.vimeo.com/video/1055179361?h=9e3d0f4a26&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Journey - 05"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          <p className="text-xs leading-[15px]">
            Hope this video was helpful in understanding how to make your pitch
            effective. If you have any more doubts please feel free to connect
            with your reporting manager.
          </p>

          <div className="mt-5 flex gap-[10px] text-xs">
            <input
              disabled={profile.journeyStep > 5}
              defaultChecked={profile.journeyStep > 5}
              type="checkbox"
              onChange={() => {
                setProfile({ ...profile, journeyStep: 6 });
              }}
              id="step-5"
            />
            <label className="text-secondary" htmlFor="step-5">
              I understand how to make a pitch to a donor
            </label>
          </div>
        </div>
      </>
    ),
  };

  return (
    <div>
      <div className="flex m-4">
        <div className="w-1/2">
          <DaysLeft />
        </div>
        <div className="w-1/2">
          <div className="flex justify-end gap-4">
            <CopyReferenceCode />
            <Profile />
          </div>
        </div>
      </div>

      <TopMenu />
      <div style={overlayStyle}>
        <div className="py-8 px-12">
          <div className="flex justify-center items-center gap-5">
            <div className="font-bold text-secondary">STEP</div>
            <div
              onClick={() => {
                setCurrentStep(1);
              }}
              className={`${
                (profile.journeyStep ?? 1) >= 1
                  ? "bg-secondary"
                  : "bg-[#9D9D9D]"
              } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-5 after:translate-x-full after:border-[#9d9d9d]`}
            >
              {(profile.journeyStep ?? 1) === 1 ? Lock : null}1
            </div>
            <div
              onClick={() => {
                if (profile.journeyStep >= 2) setCurrentStep(2);
              }}
              className={`${
                profile.journeyStep >= 2 ? "bg-tertiary" : "bg-[#9D9D9D]"
              } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-6 after:translate-x-full after:border-[#9d9d9d]`}
            >
              {profile.journeyStep === 2 ? Lock : null}2
            </div>
            <div
              onClick={() => {
                if (profile.journeyStep >= 3) setCurrentStep(3);
              }}
              className={`${
                profile.journeyStep >= 3 ? "bg-primary" : "bg-[#9D9D9D]"
              } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-6 after:translate-x-full after:border-[#9d9d9d]`}
            >
              {profile.journeyStep === 3 ? Lock : null}3
            </div>
            <div
              onClick={() => {
                if (profile.journeyStep >= 4) setCurrentStep(4);
              }}
              className={`${
                profile.journeyStep >= 4 ? "bg-tertiary" : "bg-[#9D9D9D]"
              } w-7 h-7 rounded-full flex justify-center items-center text-white relative after:absolute after:top-1/2 after:right-0 after:content-[''] after:border-t-[0.3px] after:border-dashed after:w-6 after:translate-x-full after:border-[#9d9d9d]`}
            >
              {profile.journeyStep === 4 ? Lock : null}4
            </div>
            <div
              onClick={() => {
                if (profile.journeyStep >= 5) setCurrentStep(5);
              }}
              className={`${
                profile.journeyStep >= 5 ? "bg-secondary" : "bg-[#9D9D9D]"
              } w-7 h-7 rounded-full flex justify-center items-center text-white`}
            >
              5
            </div>
          </div>
          <div></div>
          {steps[currentStep]}
        </div>
      </div>
    </div>
  );
};

export default Journey;
