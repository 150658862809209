import checks from "assets/images/backgrounds/checks.png";
import backArrowWhite from "assets/images/icons/back-arrow-white.png";
import RetakeTest from "components/Pages/Personality/RetakeTest";
import { personality } from "config/16-personalities/presonality";
import { AuthContext } from "context/Auth";
import useDocumentTitle from "hooks/useDocumentTitle";
import useLayoutConfig from "hooks/useLayoutConfig";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PersonalityResult = () => {
  useLayoutConfig("purple", true);
  const navigate = useNavigate();
  const { profile } = useContext(AuthContext);

  useDocumentTitle("Muskurahat Foundation | Donations");

  const handleBackClick = () => {
    navigate(-1);
  };

  const overlayStyle = {
    backgroundPosition: "center center",
    borderRadius: "0 0 35px 35px",
    backgroundColor: "#fff",
    backgroundImage: `url(${checks})`,
    marginTop: 80,
    boxShadow: "0px -4px 4px 0px rgba(0, 0, 0, 0.25)",
    minHeight: "50vh",
  };

  useEffect(() => {
    console.log(profile.personality);
    profile.personality === undefined
      ? navigate("/user/personality-test")
      : null;
  }, [profile]);

  return (
    profile?.personality && (
      <>
        <div className="flex flex-col text-white justify-center items-center mt-10 relative mb-14">
          <img
            src={backArrowWhite}
            alt="back"
            onClick={handleBackClick}
            className="absolute left-5 top-2 w-5"
          />
          <p>Your personality type is:</p>
          <h1 className="font-bold text-xl text-center flex-grow text-tertiary text-[24px] uppercase">
            {personality[profile.personality.code].niceName}
          </h1>
          <p className="text-[21px] font-bold uppercase">
            {personality[profile.personality.code].fullCode}
          </p>
        </div>

        <div
          style={overlayStyle}
          className="relative px-5 pt-[100px] pb-[30px]"
        >
          {/* Hero Image */}
          <img
            src={personality[profile.personality.code].mainImage}
            alt=""
            className="absolute -top-[70px]"
          />
          <div className="mt-20">
            <h2 className="text-center text-xl font-bold">Personality</h2>
            <div className="text-justify mt-2">
              {personality[profile.personality.code].personality}
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">Strengths</h2>
            <div className="text-justify mt-2">
              <ul className="text-justify">
                {personality[profile.personality.code].strengths.map(
                  (strength, i) => (
                    <li key={i} className="mt-2">
                      <strong>{strength.title}: </strong>
                      {strength.description}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">Weaknesses</h2>
            <div className="text-justify mt-2">
              <ul className="">
                {personality[profile.personality.code].weaknesses.map(
                  (weakness, i) => (
                    <li key={i} className="mt-2">
                      <strong>{weakness.title}: </strong>
                      {weakness.description}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">
              Romantic Relationships
            </h2>
            <div className="text-justify mt-2">
              {personality[profile.personality.code].romanticRelationships}
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">Friendships</h2>
            <div className="text-justify mt-2">
              {personality[profile.personality.code].friendship}
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">Parenthood</h2>
            <div className="text-justify mt-2">
              {personality[profile.personality.code].parenthood}
            </div>
          </div>
          <div className="mt-10">
            <h2 className="text-center text-xl font-bold">Career Paths</h2>
            <div className="text-justify mt-2">
              {personality[profile.personality.code].careerPath}
            </div>
          </div>
          <div className="mt-10 text-center">
            <RetakeTest />
          </div>
        </div>
      </>
    )
  );
};

export default PersonalityResult;
