export const personality = {
  ISTJ: {
    mainImage: "/images/personalities/istj.svg",
    niceName: "Logistician",
    fullCode: "ISTJ-A",
    avatarSrc: "",
    avatarAlt: "ISTJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Logisticians are practical and fact-minded individuals, whose reliability cannot be doubted.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        ISTJ personalities are characterized by their responsible, dependable,
        and methodical nature. They value structure, tradition, and honesty, and
        they approach life with a strong sense of duty. Known for their logical
        thinking, they remain calm and grounded even in stressful situations,
        making them reliable figures in their personal and professional lives.
        They take pride in their integrity and prefer to fulfill their
        commitments, often through disciplined actions and clear communication.
      </p>
    ),
    strengths: [
      {
        title: "Honest and Direct",
        description:
          "ISTJs are deeply committed to integrity, valuing honesty and straightforward communication. They avoid emotional manipulation and mind games, always aiming to address situations with simple, clear truth.",
      },
      {
        title: "Disciplined",
        description:
          "Patient and determined, ISTJs meet their obligations with strict adherence to rules and authority. They display a strong will to complete tasks and honor their commitments, regardless of the challenges they face.",
      },
      {
        title: "Very Responsible",
        description:
          "ISTJs are known for their strong sense of responsibility. They prioritize fulfilling their duties on time and maintaining high standards, even at the expense of their personal well-being.",
      },
      {
        title: "Calm and Practical",
        description:
          "ISTJs are pragmatic, making rational and effective decisions without losing their composure. They prioritize practicality over emotions, ensuring that decisions align with the reality of a situation.",
      },
      {
        title: "Organized and Effective",
        description:
          "ISTJs excel in environments with clear structure and guidelines. Their effectiveness stems from their methodical approach and adherence to a well-organized system.",
      },
    ],
    weaknesses: [
      {
        title: "Stubborn",
        description:
          "ISTJs are often resistant to new ideas, especially those that contradict their established beliefs or facts. This makes it difficult for them to admit when they are wrong or to accept changes that are outside their framework.",
      },
      {
        title: "Insensitive",
        description:
          "While honest, ISTJs can sometimes be unintentionally harsh, prioritizing facts over emotions. Their straightforwardness may hurt more sensitive individuals, especially when emotions are involved.",
      },
      {
        title: "Always by the Book",
        description:
          "ISTJs tend to follow established rules and procedures rigidly, which can make them resistant to trying new approaches, even when the risks are minimal.",
      },
      {
        title: "Judgmental",
        description:
          "Due to their reliance on concrete evidence, ISTJs may struggle to respect people who disregard facts. They can become judgmental, seeing others as irrational when they rely on assumptions or emotions instead of proven information.",
      },
      {
        title: "Prone to Burnout",
        description:
          "ISTJs often take on too much responsibility, believing that they alone can be relied upon to deliver. This can lead to exhaustion and burnout if they don’t learn to delegate tasks or set boundaries.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ISTJs are deeply committed partners who value stability and structure
          in their romantic relationships. They are dedicated and will make
          significant efforts to ensure their relationships are stable and
          mutually satisfying. They prefer traditional dating methods and are
          drawn to familiar settings.
        </p>
        <p>
          While they may struggle to express emotions freely, their dedication
          and responsibility shine through. They can offer emotional support,
          but it often comes once they recognize the need. Their partners may
          find their approach to be somewhat rigid, but the ISTJ&apos;s
          reliability and steadfast commitment are valued traits.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ISTJs are loyal and dependable friends, though their friendships tend
          to be smaller and more selective. They value trust and commitment over
          spontaneity, and they prefer to maintain long-term connections with
          people who share their values.
        </p>
        <p>
          While they may not be the most expressive in showing affection, ISTJs
          show their care through actions, offering reliability and support.
          They tend to be more comfortable with friends who share similar
          perspectives but can still appreciate differences and respect diverse
          qualities in others.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ISTJs take their responsibilities seriously, raising their
          children in structured environments with high standards. They instill
          values like hard work and respect for authority, but their strict
          approach can sometimes create emotional distance between them and
          their children.
        </p>
        <p>
          ISTJs need to develop more empathy as their children grow, learning to
          balance rational discipline with emotional support. When they can meet
          their children halfway, their parenting can foster mutual respect and
          accomplishment.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ISTJs are well-suited to careers that offer stability, structure, and
          respect for tradition. They thrive in roles that require
          responsibility and consistency, such as military officers, lawyers,
          and financial managers. They are focused on building long-term,
          dependable careers rather than seeking flexibility.
        </p>
        <p>
          While they prefer working alone, ISTJs are also capable of working in
          teams when roles are clearly defined. Their reliability and attention
          to detail make them successful in a variety of professions that
          require sharp focus and organizational skills.
        </p>
      </>
    ),
  },
  ISFJ: {
    mainImage: "/images/personalities/isfj.svg",
    niceName: "Defender",
    fullCode: "ISFJ-A",
    avatarSrc: "",
    avatarAlt: "ISFJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Defenders are very dedicated and warm protectors, always ready to defend their loved ones.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        People with the ISFJ personality type (Defenders) are hardworking,
        dependable, and devoted. They have a strong sense of responsibility
        towards others and are deeply caring, often making their loved ones feel
        valued and supported. They are loyal and committed, but rarely seek
        recognition for their efforts. ISFJs can also be analytical and
        detail-oriented, and though introverted, they are skilled at building
        strong social connections and relationships.
      </p>
    ),
    strengths: [
      {
        title: "Supportive",
        description:
          "ISFJs genuinely enjoy helping others and take pride in offering knowledge and assistance. They strive for teamwork and aim for win-win situations.",
      },
      {
        title: "Reliable",
        description:
          "ISFJs are steady and consistent in their work. They approach tasks carefully, going beyond expectations to ensure everything is completed to the highest standard.",
      },
      {
        title: "Observant",
        description:
          "ISFJs have a keen eye for detail, often noticing small things about people that others might miss. This helps them understand emotions and situations better.",
      },
      {
        title: "Enthusiastic",
        description:
          "ISFJs become highly motivated when working on goals that they believe will make a positive impact, putting their energy and efforts into meaningful tasks.",
      },
      {
        title: "Hardworking",
        description:
          "ISFJs are committed to their work and take pride in going above and beyond. They form emotional attachments to projects and organizations, ensuring they contribute more than their share.",
      },
      {
        title: "Good Practical Skills",
        description:
          "ISFJs combine an altruistic nature with practical action, always ready to step in and help others with their hands-on approach.",
      },
    ],
    weaknesses: [
      {
        title: "Overly Humble",
        description:
          "ISFJs tend to downplay their achievements and may avoid claiming credit, concerned about others’ feelings. This can make them feel unappreciated.",
      },
      {
        title: "Taking Things Personally",
        description:
          "ISFJs are sensitive to criticism and may feel personally attacked when they face disagreement or disapproval, even if it’s not intended as such.",
      },
      {
        title: "Repressing Their Feelings",
        description:
          "ISFJs tend to keep their negative emotions to themselves, leading to misunderstandings and sudden outbursts when frustration builds up.",
      },
      {
        title: "Reluctant to Change",
        description:
          "ISFJs struggle with change, particularly when it’s sudden or disrupts established traditions. They may resist change until the situation becomes unavoidable.",
      },
      {
        title: "Too Altruistic",
        description:
          "Their generous nature can leave ISFJs vulnerable to being taken advantage of. They often prioritize others' needs over their own, making it hard for them to confront those who aren’t pulling their weight.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ISFJs in romantic relationships are deeply committed and loyal,
          showing love through consistent, thoughtful gestures rather than grand
          declarations. They may struggle to initiate relationships but are
          incredibly dedicated when they do commit.
        </p>
        <p>
          While they give a lot to their partners, they also seek love and
          appreciation in return. They may face challenges in breaking off
          relationships that aren’t working but are fiercely loyal and dedicated
          to ensuring their partner&apos;s happiness.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ISFJs value their friendships deeply and are warm, supportive friends.
          However, they tend to be protective of themselves and take time to
          open up, preferring to build connections gradually over time.
        </p>
        <p>
          While they are caring and reliable friends, they may fall into
          people-pleasing tendencies, sometimes neglecting their own needs for
          the sake of maintaining harmony in their friendships.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ISFJs are dedicated and responsible, seeing parenthood as
          a calling. They bring love and care to their children while upholding
          traditions and guiding them with a sense of authority and wisdom.
        </p>
        <p>
          ISFJ parents may struggle with their children’s independence but are
          committed to teaching them responsibility and ensuring they grow up
          with strong values and a sense of duty.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          In their careers, ISFJs are reliable and hardworking, often thriving
          in roles that require attention to detail and a sense of duty. They
          tend to excel in jobs that allow them to help others and make a
          tangible difference.
        </p>
        <p>
          They may find it challenging to assert themselves or embrace changes
          in the workplace but can achieve great success through consistent
          effort and dedication.
        </p>
      </>
    ),
  },
  INFJ: {
    mainImage: "/images/personalities/infj.svg",
    niceName: "Advocate",
    fullCode: "INFJ-A",
    avatarSrc: "",
    avatarAlt: "INFJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Advocates are quiet and mystical, yet very inspiring and tireless idealists.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        Idealistic and principled, INFJs are driven by a deep desire to make a
        positive difference in the world. They value integrity and are guided by
        their own wisdom and intuition, aiming to live in alignment with their
        personal values. Despite their reserved nature, INFJs are passionate,
        empathetic, and dedicated to helping others.
      </p>
    ),
    strengths: [
      {
        title: "Insightful",
        description:
          "INFJs have an uncanny ability to understand people's true motivations and emotions, moving beyond superficial appearances to uncover deeper truths.",
      },
      {
        title: "Principled",
        description:
          "They hold strong beliefs and values, especially around ethics, and avoid deceiving others, even when it could benefit them.",
      },
      {
        title: "Passionate",
        description:
          "INFJs are driven by a sense of purpose and actively pursue their dreams, energized by their idealistic visions for the future.",
      },
      {
        title: "Altruistic",
        description:
          "Their actions are motivated by a desire to contribute to the greater good, and they remain mindful of how their words and actions affect others.",
      },
      {
        title: "Creative",
        description:
          "INFJs embrace their creativity, constantly seeking opportunities to think outside the box and express themselves in unique ways.",
      },
    ],
    weaknesses: [
      {
        title: "Sensitive to Criticism",
        description:
          "INFJs can be defensive and upset when criticized, especially if it challenges their core values or principles.",
      },
      {
        title: "Reluctant to Open Up",
        description:
          "Though they value authenticity, INFJs may struggle to open up about their own struggles, leading to potential distance in relationships.",
      },
      {
        title: "Perfectionistic",
        description:
          "Their idealism can lead to a constant focus on imperfections, making it difficult for them to appreciate the present moment or situations.",
      },
      {
        title: "Avoiding the Ordinary",
        description:
          "INFJs desire extraordinary lives but may struggle to break down their grand visions into practical steps, hindering progress.",
      },
      {
        title: "Prone to Burnout",
        description:
          "Their tendency to overextend themselves for others can lead to burnout if they don’t prioritize self-care.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          INFJs look for deep, meaningful connections in their romantic
          relationships. They seek partners who share their core values and are
          attuned to their emotional and spiritual needs. When they find a
          compatible partner, INFJs are warm, honest, and committed to building
          a strong, sincere bond.
        </p>
        <p>
          Their ideal romantic relationship involves open communication and
          growth, where both partners contribute to each other&apos;s personal
          development. INFJs&apos; deep emotional connection with their partners
          allows them to create a relationship that is full of trust,
          understanding, and shared vision.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          INFJs value authentic, deep friendships. They seek kindred spirits
          with whom they can share their passions, dreams, and ideals. They
          cherish loyalty and mutual support in their friendships and enjoy
          helping each other grow in meaningful ways.
        </p>
        <p>
          Although INFJs may struggle to find friends who share their values,
          when they do, the bonds are deep and lasting. They prioritize
          authenticity and are fiercely loyal to their true friends, willing to
          make sacrifices to maintain those meaningful relationships.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          INFJ parents are committed to raising children who are emotionally
          intelligent and compassionate. They take their responsibilities
          seriously and aim to instill humanitarian values in their children
          while encouraging independence and integrity.
        </p>
        <p>
          Though INFJs may face challenges in balancing their idealistic
          expectations with their children&apos;s individuality, they ultimately
          strive to create a nurturing environment where their children can
          develop their own values and principles.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          INFJs seek careers that align with their values and give them a sense
          of purpose. They are drawn to professions where they can help others
          and contribute to positive change, using their creativity and empathy
          to make a meaningful impact.
        </p>
        <p>
          They thrive in environments that allow them to pursue their passions
          and bring about positive change, but they may struggle with
          perfectionism and burnout if they don&apos;t take the time to care for
          themselves.
        </p>
      </>
    ),
  },
  INTJ: {
    mainImage: "/images/personalities/intj.svg",
    niceName: "Architect",
    fullCode: "INTJ-A",
    avatarSrc: "",
    avatarAlt: "INTJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Architects are imaginative and strategic thinkers, with a plan for everything.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },

      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },

      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        INTJs (Architects) are intellectually curious individuals with a strong
        desire for self-improvement. They value rationality, creativity, and
        mastery of topics that interest them. Known for their independence,
        INTJs prefer to think and act alone, often immersing themselves in their
        own minds to solve problems and seek out new knowledge. Their
        introspective nature is balanced by a deep emotional sensitivity, which
        they may not always express outwardly but feel profoundly.
      </p>
    ),
    strengths: [
      {
        title: "Rational",
        description:
          "INTJs are known for their powerful intellect, which allows them to approach challenges with rational thinking and inventive solutions.",
      },
      {
        title: "Informed",
        description:
          "They base their conclusions on research and analysis, ensuring their beliefs are grounded in facts rather than assumptions.",
      },
      {
        title: "Independent",
        description:
          "INTJs are creative and self-motivated, preferring to make decisions on their own rather than rely on others' opinions.",
      },
      {
        title: "Determined",
        description:
          "They are highly goal-oriented and will not rest until they’ve mastered their chosen pursuits, facing challenges head-on.",
      },
      {
        title: "Curious",
        description:
          "INTJs have a thirst for knowledge and are open to new ideas as long as they are backed by logic and evidence.",
      },
      {
        title: "Original",
        description:
          "They bring unconventional ideas and inventions to the world, often challenging others to look at things in new ways.",
      },
    ],
    weaknesses: [
      {
        title: "Arrogant",
        description:
          "Their self-assurance can sometimes make them dismissive of others' input, especially if they deem others intellectually inferior.",
      },
      {
        title: "Dismissive of Emotions",
        description:
          "INTJs prioritize rationality, often ignoring emotional context, which can cloud their judgment in relationships.",
      },
      {
        title: "Overly Critical",
        description:
          "They tend to be harshly critical, holding others to high standards and failing to consider human nature in their assessments.",
      },
      {
        title: "Combative",
        description:
          "INTJs dislike following arbitrary rules, sometimes arguing over trivial matters instead of focusing on more important issues.",
      },
      {
        title: "Socially Clueless",
        description:
          "Their intense rationality can lead to frustration in social settings, making them appear disconnected or cynical about relationships.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          INTJs approach romance strategically, seeking both companionship and
          intellectual stimulation. They value honesty and depth but may find it
          difficult to navigate the irrational aspects of love. Their tendency
          to overanalyze potential partners can lead to dissatisfaction if they
          don&apos;t find someone who meets their high expectations.
        </p>
        <p>
          As they mature, INTJs may come to appreciate the unspoken rituals of
          romance, learning to balance their intellectual approach with
          emotional understanding. Despite their reserved nature, they can form
          deep connections if they learn to engage with their feelings and
          remain open to vulnerability.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          INTJs value deep, intellectual connections in their friendships. They
          prefer a few close friends over a large social circle and seek those
          who can match their mental curiosity and challenge their ideas. They
          are fiercely loyal and will offer logical support to their friends,
          but may struggle to provide emotional comfort.
        </p>
        <p>
          An ideal friendship for an INTJ is based on mutual respect,
          independence, and low drama. They value intellectual stimulation over
          social conventions and cherish the freedom to engage in meaningful
          conversations without obligations or expectations.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, INTJs are serious and committed, focusing on shaping their
          children’s intellect and independence. They aim to foster critical
          thinking and self-reliance in their children, avoiding arbitrary rules
          in favor of promoting responsibility and maturity.
        </p>
        <p>
          While INTJs may not be the most emotionally expressive, they strive
          for an honest, straightforward connection with their children, guiding
          them to become capable and thoughtful individuals who can think for
          themselves.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          In their careers, INTJs are determined and goal-oriented, driven by a
          desire to master their chosen field. Their independent nature often
          leads them to take charge of their own projects, but they are also
          open to strategic collaboration when it aligns with their goals.
        </p>
        <p>
          INTJs excel in environments that value logic, structure, and
          innovation. They are most fulfilled when they can solve complex
          problems and push the boundaries of what is possible, bringing both
          intellect and determination to their professional endeavors.
        </p>
      </>
    ),
  },
  ISTP: {
    mainImage: "/images/personalities/istp.svg",
    niceName: "Virtuoso",
    fullCode: "ISTP-A",
    avatarSrc: "",
    avatarAlt: "ISTP avatar",
    avatarSrcStatic: "",
    snippet:
      "Virtuosos are innovative and practical experimenters, masters of all kinds of tools.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        People with the ISTP personality type (Virtuosos) are curious, hands-on,
        and love exploring the world around them through action. They are
        independent, self-reliant, and value their personal space. They prefer
        solving problems directly and practically, often relying on experience
        rather than theory. Though they can appear reserved and spontaneous,
        they are deeply passionate and can shift their focus rapidly to new
        interests.
      </p>
    ),
    strengths: [
      {
        title: "Diligent and Observant",
        description:
          "ISTPs are hands-on individuals who enjoy transforming their observations into practical solutions, engaging deeply with their environment.",
      },
      {
        title: "Handy and Resourceful",
        description:
          "ISTPs are imaginative and skilled at applying their creativity to practical tasks, especially in mechanics, crafts, and building things.",
      },
      {
        title: "Spontaneous",
        description:
          "They are flexible and able to think on their feet, releasing energy just when it's needed most, though this can come with unpredictability.",
      },
      {
        title: "Direct and Authentic",
        description:
          "ISTPs value straightforward communication and are known for their honest, no-nonsense approach in relationships and decision-making.",
      },
      {
        title: "Independent",
        description:
          "They thrive on independence, preferring to work alone and chart their own course, which helps foster creativity and strong problem-solving abilities.",
      },
      {
        title: "Grounded",
        description:
          "ISTPs have a keen ability to stay rooted in the present moment, focusing on what is happening now rather than dwelling on the past or future.",
      },
    ],
    weaknesses: [
      {
        title: "Unapologetic",
        description:
          "ISTPs can be blunt and unconsiderate of others' preferences, often moving in their own direction without taking others into account.",
      },
      {
        title: "Insensitive",
        description:
          "Despite their logical nature, ISTPs struggle with expressing empathy and emotional sensitivity, which can lead to misinterpretations of their intentions.",
      },
      {
        title: "Private and Reserved",
        description:
          "They prefer to keep their personal matters to themselves and often avoid small talk, which can make them difficult to get to know.",
      },
      {
        title: "Easily Bored",
        description:
          "Once something no longer excites them, ISTPs quickly move on to new and more interesting tasks, which can affect their focus and consistency.",
      },
      {
        title: "Overly Skeptical",
        description:
          "While skepticism helps them avoid unwarranted assumptions, it can lead them to dismiss subjective truths and pass up opportunities.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ISTPs enjoy complex, spontaneous relationships that respect their
          independence. They appreciate space and do not feel obligated to
          follow societal expectations for commitment or consistency. Their
          relationships progress naturally, and intimacy is an avenue for
          expressing deeper emotions.
        </p>
        <p>
          Communication can be a challenge, as ISTPs often leave things unsaid,
          and their need for freedom can make them resistant to forced
          discussions about the future. They need a partner who respects their
          need for space and allows the relationship to evolve on its own terms.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ISTPs are selective about their friends and value independence in
          their relationships. They enjoy flexible, low-pressure friendships and
          prefer spending time with people who respect their need for space and
          spontaneity. Their friendships are often based on shared activities,
          especially hands-on tasks or hobbies.
        </p>
        <p>
          Although they can be charming and popular, they reserve their true
          friendship for a select few. Conversations that involve abstract ideas
          or endless talking without action often lose their interest, and they
          prefer to focus on tangible, practical experiences.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ISTP parents give their children freedom to explore and express
          themselves within reasonable limits. They encourage hands-on learning
          and enjoy sharing their passion for creating and building. They
          believe in letting their children find their own path rather than
          imposing their values or interests.
        </p>
        <p>
          However, ISTP parents struggle with emotional bonding, as they tend to
          be less expressive about their feelings. They must work hard to
          provide emotional support for their children and learn how to nurture
          their children&apos;s emotional growth, especially during the teenage
          years.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ISTPs thrive in careers that offer variety, independence, and
          practicality. They excel in roles that require problem-solving and
          hands-on skills, such as mechanics, forensic scientists, or crisis
          responders. They value work that gives them freedom and avoids rigid
          structures or unnecessary socializing.
        </p>
        <p>
          The need for flexibility and the ability to work on their own terms
          often leads ISTPs to entrepreneurial ventures. They are also
          well-suited for jobs that involve immediate, tangible results and are
          less interested in abstract theories or purely academic pursuits.
        </p>
      </>
    ),
  },
  ISFP: {
    mainImage: "/images/personalities/isfp.svg",
    niceName: "Adventurer",
    fullCode: "ISFP-A",
    avatarSrc: "",
    avatarAlt: "ISFP avatar",
    avatarSrcStatic: "",
    snippet:
      "Adventurers are flexible and charming artists, always ready to explore and experience something new.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        I change during the course of a day. I wake and I’m one person, and when
        I go to sleep I know for certain I’m somebody else. ISFPs (Adventurers)
        are artists at heart, expressing themselves through life in unique ways,
        from what they wear to how they spend their time. Their ability to find
        joy in everyday life makes them intriguing individuals, marked by
        fairness and a love for inspiring others.
      </p>
    ),
    strengths: [
      {
        title: "Charming",
        description:
          "ISFPs are relaxed and warm, with a 'live and let live' attitude that makes them likable and popular.",
      },
      {
        title: "Sensitive to Others",
        description:
          "ISFPs can easily relate to others’ emotions, helping create harmony and minimize conflict.",
      },
      {
        title: "Kind and Encouraging",
        description:
          "Their innate kindness fuels empathy, providing a safe space for others to express themselves.",
      },
      {
        title: "Imaginative",
        description:
          "ISFPs use creativity to craft bold ideas that speak to people's hearts, with vivid imagination and an exploratory spirit.",
      },
      {
        title: "Passionate",
        description:
          "When ISFPs are passionate about something, they are fully engaged, leaving everything else behind.",
      },
    ],
    weaknesses: [
      {
        title: "Difficulty with Structure",
        description:
          "ISFPs prioritize freedom of expression, making rigid structures and rules challenging.",
      },
      {
        title: "Unpredictable",
        description:
          "Their tendency to avoid long-term plans can cause strain in relationships and lead to financial uncertainty.",
      },
      {
        title: "Easily Stressed",
        description:
          "Living in the present, ISFPs can shut down when situations get overwhelming, losing their usual charm and creativity.",
      },
      {
        title: "Difficulty with Technical Problem-Solving",
        description:
          "ISFPs prefer observation over hands-on involvement, which can hinder their ability to solve technical problems.",
      },
      {
        title: "Fluctuating Self-Esteem",
        description:
          "ISFPs' efforts are often undervalued, which can harm their self-esteem, especially early in life.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ISFPs are mysterious and emotional, guarding their sensitive core
          while focusing on their partner&apos;s needs. Their spontaneity brings
          vibrancy to relationships, often surprising their partners with acts
          of love and devotion.
        </p>
        <p>
          Although they may struggle with long-term planning, they enjoy living
          in the moment with their partner, and appreciate being valued through
          actions. Emotional support and appreciation are crucial for their
          well-being in relationships.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          In friendships, ISFPs are laid-back, spontaneous, and empathetic. They
          enjoy casual activities and provide a supportive presence during times
          of crisis. They value individuality and prefer a non-judgmental
          environment in their friendships.
        </p>
        <p>
          ISFPs build trust over time and shed their reservations in
          comfortable, understanding relationships. They are happy to engage in
          spontaneous adventures, fostering bonds marked by warmth and empathy.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ISFPs foster a nonjudgmental environment for their children, allowing
          them the freedom to express themselves and explore. They enjoy quality
          time and engaging in spontaneous activities, making every day a new
          opportunity for joy and learning.
        </p>
        <p>
          As parents, ISFPs are relaxed and practical, focusing on building
          open-mindedness in their children. They value personal freedom and
          enjoy watching their children grow, but may struggle with the
          challenges of their teens, needing reassurance that their efforts are
          appreciated.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ISFPs thrive in careers that offer creative freedom and a chance for
          self-expression. They gravitate toward fields like art, music,
          photography, and design, where they can use their imagination and
          unique perspective to bring ideas to life.
        </p>
        <p>
          They dislike rigid structures and prefer flexible, immersive work
          environments that allow for spontaneity and innovation. For ISFPs, a
          job is more than just a paycheck; it&apos;s an outlet for creativity
          and passion.
        </p>
      </>
    ),
  },
  INFP: {
    mainImage: "/images/personalities/infp.svg",
    niceName: "Mediator",
    fullCode: "INFP-A",
    avatarSrc: "",
    avatarAlt: "INFP avatar",
    avatarSrcStatic: "",
    snippet:
      "Mediators are poetic, kind, and altruistic people, always eager to help a good cause.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        Although they may seem quiet or unassuming, people with the INFP
        personality type (Mediators) have vibrant, passionate inner lives.
        Creative and imaginative, they happily lose themselves in daydreams,
        inventing all sorts of stories and conversations in their mind. They are
        sensitive, idealistic, and empathetic, seeking deep, soulful
        relationships, and often feeling like they don’t fit into the fast-paced
        world around them. Their unique creativity and sensitivity allow them to
        connect deeply with others and initiate positive change.
      </p>
    ),
    strengths: [
      {
        title: "Empathetic",
        description:
          "INFPs can truly feel the emotions of others, from joy to sorrow, and they are deeply thoughtful and kindhearted, always striving to avoid hurting anyone, even unintentionally.",
      },
      {
        title: "Generous",
        description:
          "INFPs are motivated by a desire to share the good things in their lives, uplift others, and contribute to a world where everyone’s needs are met.",
      },
      {
        title: "Open-Minded",
        description:
          "They are tolerant and accepting, preferring compassion over faultfinding, and often become confidants for friends and strangers alike.",
      },
      {
        title: "Creative",
        description:
          "INFPs love exploring unconventional ideas, often engaging in creative pursuits like writing or art, fueled by their rich imagination.",
      },
      {
        title: "Passionate",
        description:
          "INFPs feel deeply about the causes that speak to their beliefs, often dedicating themselves wholeheartedly to a cause they care about.",
      },
      {
        title: "Idealistic",
        description:
          "They strive to live a meaningful life guided by their conscience, often aiming to make the world a better place despite the imperfections they see.",
      },
    ],
    weaknesses: [
      {
        title: "Unrealistic",
        description:
          "INFPs may struggle with disappointment when their idealistic vision of life doesn’t match reality, sometimes leading to hopeless romanticism.",
      },
      {
        title: "Self-Isolating",
        description:
          "While they crave connection, INFPs may have difficulty reaching out and may experience feelings of loneliness or isolation.",
      },
      {
        title: "Unfocused",
        description:
          "Their introspective nature can sometimes lead to a lack of productivity, as they become distracted by various ideas and fail to commit to one course of action.",
      },
      {
        title: "Emotionally Vulnerable",
        description:
          "Their deep sensitivity can cause them to absorb the negative emotions of others, which may overwhelm them unless they set boundaries.",
      },
      {
        title: "Too Eager to Please",
        description:
          "INFPs tend to avoid conflict and may go to great lengths to please others, even at the expense of their own needs, sometimes leading to emotional exhaustion.",
      },
      {
        title: "Self-Critical",
        description:
          "INFPs may set high expectations for themselves and struggle with self-criticism when they fail to live up to these ideals, which can discourage them from pursuing their dreams.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          INFPs are idealistic romantics, believing in the beauty of true love
          and longing for deep, soulful connections with a partner. They often
          have high expectations for relationships and seek someone with whom
          they can share their deepest hopes, dreams, and fears.
        </p>
        <p>
          Despite their high standards, INFPs can be flexible and understanding,
          learning that love requires compromise and effort. They express their
          affection in creative ways and dedicate themselves to helping their
          partner grow, but they must ensure they don’t lose sight of their own
          needs in the process.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          INFPs value close, deep friendships and find fulfillment in a small,
          intimate circle of friends. They prefer relationships where they can
          share their wildest dreams and ideas without judgment, and they tend
          to feel most at ease with people who share their desire for meaningful
          connections.
        </p>
        <p>
          Although INFPs can be warm and accepting, they often need personal
          space to recharge. Their friendships are marked by loyalty, emotional
          insight, and a protective streak, and they cherish the deep bonds they
          form with others over time.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          INFPs, with their passion for helping others, approach parenthood with
          a deep desire to guide their children toward fulfillment and
          happiness. They offer gentle, non-dominant guidance, encouraging their
          children to explore and grow in their own unique ways.
        </p>
        <p>
          They take pride in being a supportive and loving parent, and they work
          to create an environment where their children feel safe, understood,
          and encouraged to pursue their own dreams.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          INFPs are driven by a desire to make a positive impact in the world.
          They often feel most fulfilled in careers that align with their ideals
          and creativity, such as writing, art, counseling, or humanitarian
          work.
        </p>
        <p>
          Although they may struggle with the practical demands of certain jobs,
          INFPs are at their best when they can contribute meaningfully to a
          cause that reflects their values and sense of purpose.
        </p>
      </>
    ),
  },
  INTP: {
    mainImage: "/images/personalities/intp.svg",
    niceName: "Logician",
    fullCode: "INTP-A",
    avatarSrc: "",
    avatarAlt: "INTP avatar",
    avatarSrcStatic: "",
    snippet:
      "Logicians are innovative inventors with an unquenchable thirst for knowledge.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "introversion",
        label: "Energy",
        color: "blue",
        score: 18,
        pct: 45,
        trait: "Introversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Introverted individuals are reflective, reserved, and prefer solitary activities. They tend to focus on their own thoughts and feelings.",
        snippet:
          "You’re reflective and prefer activities that allow for introspection.",
        imageAlt: "A person sitting quietly and reading a book.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_introversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        People with the INTP personality type (Logicians) are deeply curious and
        intellectual. They pride themselves on their unique perspective, often
        immersing themselves in thought and analysis. Preferring solitude, they
        find joy in discovering new ideas and exploring the mysteries of the
        universe. Although they may appear detached, when they connect with
        someone who shares their intellectual curiosity, they come alive with
        enthusiasm and creativity.
      </p>
    ),
    strengths: [
      {
        title: "Analytical",
        description:
          "INTPs have a natural ability to analyze everything they encounter, often spotting patterns and connections others might miss.",
      },
      {
        title: "Original",
        description:
          "With their imagination, INTPs come up with creative and unconventional ideas, contributing to innovation in various fields.",
      },
      {
        title: "Open-Minded",
        description:
          "INTPs are driven by curiosity and are willing to change their perspectives, embracing new ideas that resonate with them intellectually.",
      },
      {
        title: "Curious",
        description:
          "Always seeking new knowledge, INTPs dive deeply into subjects of interest, becoming fully engrossed in learning.",
      },
      {
        title: "Honest",
        description:
          "Committed to uncovering the truth, INTPs seek honesty in themselves and others, avoiding bias and misinformation.",
      },
    ],
    weaknesses: [
      {
        title: "Disconnected",
        description:
          "INTPs can become lost in their thoughts, sometimes missing out on social interactions or feeling detached from others.",
      },
      {
        title: "Insensitive",
        description:
          "Their focus on logic may lead them to underestimate the importance of emotions, sometimes coming across as unkind despite good intentions.",
      },
      {
        title: "Dissatisfied",
        description:
          "INTPs are often searching for ways to improve things, which can lead to a sense of dissatisfaction as they continuously look for problems to solve.",
      },
      {
        title: "Overthinkers",
        description:
          "INTPs' minds are constantly active, and sometimes they overthink situations, leading to analysis paralysis and indecision.",
      },
      {
        title: "Impatient",
        description:
          "INTPs value sharing their knowledge, but may become impatient with others who don't grasp their ideas quickly or show interest.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          INTPs bring a mix of intellectual curiosity and creativity to their
          romantic relationships. They seek partners who can challenge their
          ideas and inspire growth. Although reserved, they open up to someone
          who can engage with their thoughts, and their romantic connections are
          centered around mutual learning and discovery.
        </p>
        <p>
          Conflict in relationships may be challenging for INTPs as they often
          retreat into logic rather than addressing emotional issues. However,
          with maturity, they learn to navigate these difficulties,
          understanding the importance of emotions in maintaining a healthy
          relationship.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          INTPs value intellectual depth in friendships. They are selective in
          choosing friends who share their passion for ideas and exploration.
          Once they connect with someone on an intellectual level, they form
          deep, meaningful bonds and engage in stimulating conversations.
        </p>
        <p>
          While INTPs may find it challenging to offer emotional support, they
          excel at offering logical solutions and advice. They value
          intellectual exchange over emotional connections but understand the
          need to be present for their friends during difficult times.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          INTP parents encourage their children&apos;s independence and critical
          thinking. They foster an environment of intellectual growth and
          curiosity, allowing their children to explore ideas and form their own
          opinions.
        </p>
        <p>
          While INTP parents may struggle with offering emotional support, they
          can learn to balance their intellectual approach with the need for
          nurturing, ensuring their children develop into independent thinkers
          with the right guidance and boundaries.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          INTPs thrive in careers that challenge their intellect and curiosity,
          where they can explore complex problems and offer creative solutions.
          They excel in fields like science, technology, and philosophy, where
          their analytical and imaginative strengths can shine.
        </p>
        <p>
          However, INTPs may struggle with the practicalities of daily work,
          particularly tasks that don’t engage their creative minds. Their ideal
          career is one that offers both intellectual stimulation and room for
          innovation.
        </p>
      </>
    ),
  },
  ESTP: {
    mainImage: "/images/personalities/estp.svg",
    niceName: "Entrepreneur",
    fullCode: "ESTP-A",
    avatarSrc: "",
    avatarAlt: "ESTP avatar",
    avatarSrcStatic: "",
    snippet:
      "Entrepreneurs are smart, energetic, and very perceptive people, who truly enjoy living on the edge.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        People with the ESTP personality type, often referred to as
        Entrepreneurs, are vibrant, energetic, and spontaneous. They thrive in
        action and competition, focusing on the present moment rather than
        dwelling on the past. Their driven nature and love for hands-on
        activities make them natural leaders, known for their bold and original
        approaches to problem-solving and decision-making.
      </p>
    ),
    strengths: [
      {
        title: "Bold",
        description:
          "ESTPs are full of life and energy, constantly seeking new boundaries and applying innovative ideas.",
      },
      {
        title: "Rational and Practical",
        description:
          "ESTPs focus on actionable knowledge, preferring to find practical uses for ideas rather than engaging in abstract discussions.",
      },
      {
        title: "Original",
        description:
          "They combine boldness and practicality to experiment with new ideas and create unique solutions.",
      },
      {
        title: "Perceptive",
        description:
          "ESTPs notice small changes and use their observations to make connections and adapt quickly.",
      },
      {
        title: "Direct",
        description:
          "ESTPs are straightforward communicators, preferring clear, factual discussions over ambiguity.",
      },
      {
        title: "Sociable",
        description:
          "Natural group leaders, ESTPs are great at making use of social interactions and networking.",
      },
    ],
    weaknesses: [
      {
        title: "Insensitive",
        description:
          "ESTPs tend to prioritize facts over emotions, often struggling with emotionally charged situations and their own feelings.",
      },
      {
        title: "Impatient",
        description:
          "They prefer moving at their own pace and find it difficult to focus on slow or repetitive tasks.",
      },
      {
        title: "Impulsive",
        description:
          "Their desire for excitement can lead to hasty decisions without considering long-term consequences.",
      },
      {
        title: "Unstructured",
        description:
          "ESTPs often act on the spur of the moment, ignoring rules and expectations, which can cause social fallout.",
      },
      {
        title: "May Miss the Bigger Picture",
        description:
          "Their focus on immediate tasks sometimes leads to overlooking how individual pieces fit into the larger picture.",
      },
      {
        title: "Defiant",
        description:
          "ESTPs resist being boxed in by rules and structures, which can make traditional environments like school or entry-level jobs frustrating for them.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          In romantic relationships, ESTPs are spontaneous and enjoy the thrill
          of the present moment. They are not focused on long-term planning but
          rather on enjoying the experiences they share with their partner.
        </p>
        <p>
          While they can struggle with emotional intimacy, their adaptability
          and problem-solving abilities help maintain healthy relationships,
          provided their partner can keep up with their adventurous spirit.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ESTPs are the life of any social gathering, effortlessly bringing
          energy and humor to their friendships. They thrive in dynamic
          environments and make friends easily, often leading their group into
          spontaneous adventures.
        </p>
        <p>
          Though they enjoy intellectual conversations, their friendships are
          more about action and shared experiences, rather than deep
          philosophical discussions. ESTPs encourage their friends to join in
          their exciting pursuits.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ESTP parents are fun-loving, hands-on, and enjoy spending time with
          their children, encouraging them to explore and try new things. Their
          parenting style is easygoing, focusing on freedom and hands-on
          learning rather than strict rules.
        </p>
        <p>
          They value direct communication with their children but may struggle
          with emotional sensitivity. However, their ability to bond through
          shared experiences allows for strong relationships with their kids.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ESTPs thrive in fast-paced, action-oriented careers that allow them to
          think on their feet and make quick decisions. Their sociability and
          natural leadership skills make them successful in roles like sales,
          business negotiations, and emergency response.
        </p>
        <p>
          They are best suited for careers that offer freedom and excitement,
          such as entrepreneurship or sports, where they can take risks and
          enjoy immediate results. ESTPs dislike highly structured environments
          and prefer jobs that allow them to act independently.
        </p>
      </>
    ),
  },
  ESFP: {
    mainImage: "/images/personalities/esfp.svg",
    niceName: "Entertainer",
    fullCode: "ESFP-A",
    avatarSrc: "",
    avatarAlt: "ESFP avatar",
    avatarSrcStatic: "",
    snippet:
      "Entertainers are spontaneous, energetic, and enthusiastic performers – life is never boring around them.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        ESFPs are energetic, fun-loving, and spontaneous individuals who thrive
        in social environments. They live in the moment, bringing excitement to
        every situation and encouraging others to do the same. With a strong
        sense of style and curiosity, they are natural performers who enjoy
        being the center of attention and often use their charm to connect with
        others.
      </p>
    ),
    strengths: [
      {
        title: "Bold",
        description:
          "ESFPs are not afraid to step out of their comfort zones and embrace new experiences. They love exploring the unknown and pushing boundaries.",
      },
      {
        title: "Original",
        description:
          "ESFPs thrive on creativity and self-expression. They constantly find new ways to stand out, experimenting with different styles and approaches to life.",
      },
      {
        title: "Positive and Enthusiastic",
        description:
          "Their infectious positivity and enthusiasm light up any room, inspiring others to approach life with a similar open-mindedness.",
      },
      {
        title: "Hands-on and Observant",
        description:
          "ESFPs prefer to engage with the world through direct experiences rather than abstract ideas, which makes them highly observant and action-oriented.",
      },
      {
        title: "Excellent People Skills",
        description:
          "ESFPs are talkative, witty, and always ready to engage with others. Their sociable nature and charm make them great companions.",
      },
    ],
    weaknesses: [
      {
        title: "Sensitive",
        description:
          "Due to their strong emotions, ESFPs can be easily hurt by criticism, which makes it difficult for them to address weaknesses openly.",
      },
      {
        title: "Conflict-Averse",
        description:
          "ESFPs often avoid confrontation, preferring to maintain a light-hearted atmosphere instead of dealing with conflict directly.",
      },
      {
        title: "Easily Bored",
        description:
          "Without constant excitement, ESFPs may engage in risky or self-indulgent behaviors to create the thrill they crave.",
      },
      {
        title: "Poor Long-Term Planners",
        description:
          "ESFPs often live in the moment and struggle with planning for the future, which can lead to neglecting important long-term responsibilities.",
      },
      {
        title: "Unfocused",
        description:
          "They find it difficult to stay focused on long-term goals, particularly when tasks feel tedious or monotonous.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ESFPs approach relationships with the same excitement and energy they
          bring to all aspects of life. They value the spark of love, preferring
          passionate, dynamic connections that keep them engaged and energized.
        </p>
        <p>
          While they enjoy the thrill of romance, ESFPs can struggle with
          long-term commitments and handling criticism from partners. Their
          relationships are full of joy and affection but may lack the stability
          of slower, more methodical connections.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ESFPs are loyal and enthusiastic friends who thrive on shared
          experiences and social connections. They love creating memorable
          moments with friends, often acting as the life of the party.
        </p>
        <p>
          However, their desire for novelty can sometimes lead them to focus
          more on new friendships than maintaining long-standing ones. Despite
          this, they are deeply attuned to their friends&apos; emotions and
          offer heartfelt support during tough times.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ESFPs are warm, fun, and supportive parents who create a joyful and
          engaging environment for their children. They focus on exploration and
          hands-on learning, allowing their kids to discover the world in their
          own way.
        </p>
        <p>
          While they may not be the most disciplined, ESFPs provide plenty of
          emotional support and practical guidance, helping their children grow
          with love and encouragement.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ESFPs thrive in careers that involve creativity, social interaction,
          and excitement. They excel in roles like event planning, sales, and
          healthcare, where they can connect with people and adapt to changing
          situations.
        </p>
        <p>
          However, they struggle in careers that are repetitive, data-driven, or
          lack human interaction. They prefer jobs that allow them to express
          their creativity and make a tangible impact on others.
        </p>
      </>
    ),
  },
  ENFP: {
    mainImage: "/images/personalities/enfp.svg",
    niceName: "Campaigner",
    fullCode: "ENFP-A",
    avatarSrc: "",
    avatarAlt: "ENFP avatar",
    avatarSrcStatic: "",
    snippet:
      "Campaigners are enthusiastic, creative, and sociable free spirits, who can always find a reason to smile.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        ENFPs are outgoing, openhearted, and open-minded individuals with a
        lively and upbeat approach to life. They have a deep desire for
        meaningful emotional connections and are driven by their imagination,
        creativity, and curiosity. Although sociable and cheerful, they are
        introspective and seek to understand the world around them.
      </p>
    ),
    strengths: [
      {
        title: "Curious",
        description:
          "ENFPs find beauty and fascination in almost anything, driven by a desire to understand how things work and explore new experiences.",
      },
      {
        title: "Perceptive",
        description:
          "ENFPs are highly attuned to others' feelings and can pick up on even the smallest shifts in mood or expression.",
      },
      {
        title: "Enthusiastic",
        description:
          "ENFPs radiate positive energy when something inspires them and love to share their passion with others.",
      },
      {
        title: "Excellent Communicators",
        description:
          "ENFPs are skilled in both speaking and listening, enabling them to connect with a wide variety of people and have meaningful conversations.",
      },
      {
        title: "Easygoing",
        description:
          "ENFPs are spontaneous and lighthearted, able to enjoy the present moment and spread joy to others.",
      },
      {
        title: "Good-Natured and Positive",
        description:
          "ENFPs are warmhearted, friendly, and approachable, with a generous spirit and a desire to get along with everyone.",
      },
    ],
    weaknesses: [
      {
        title: "People-Pleasing",
        description:
          "ENFPs may struggle with saying no and compromising their values to maintain peace, often worrying when they aren't liked.",
      },
      {
        title: "Unfocused",
        description:
          "ENFPs may have difficulty maintaining focus and discipline on long-term projects, often switching interests and losing steam.",
      },
      {
        title: "Disorganized",
        description:
          "ENFPs may neglect mundane tasks like household chores or paperwork, leading to disorganization and stress.",
      },
      {
        title: "Overly Accommodating",
        description:
          "ENFPs may overcommit to helping others, leaving themselves with little time and energy for their own needs.",
      },
      {
        title: "Overly Optimistic",
        description:
          "ENFPs’ optimism can lead to naive decisions or difficulty accepting hard truths about people or situations.",
      },
      {
        title: "Restless",
        description:
          "ENFPs may feel inner dissatisfaction with certain aspects of their life, even if they appear happy outwardly.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ENFPs are passionate, affectionate, and seek deep emotional
          connections in their romantic relationships. They tend to fall in love
          quickly and invest wholeheartedly in their partners, often navigating
          challenges like long-distance relationships with optimism and
          intensity.
        </p>
        <p>
          They believe in idealistic love but may struggle when their
          relationships mature and face practical challenges. ENFPs need to
          balance their desire for excitement with the stability and commitment
          that long-term relationships require.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ENFPs are sociable, uplifting, and genuine friends, cherishing
          meaningful and authentic connections. They are skilled at making
          others feel seen and valued, but they can sometimes feel lonely if
          their emotional investment isn&apos;t reciprocated.
        </p>
        <p>
          While they thrive in friendships full of adventure and deep
          conversations, ENFPs may struggle with superficial relationships or
          overgiving, creating imbalance and potential resentment. With time,
          they learn to balance their own needs with their desire to support
          others.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ENFP parents love to share their sense of wonder with their children,
          encouraging creativity and curiosity. They inspire their children to
          be passionate about changing the world and embrace their
          individuality.
        </p>
        <p>
          ENFPs balance love and discipline by supporting their children&apos;s
          personal growth and expression, adapting to their changing interests
          and encouraging them to follow their passions.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ENFPs are driven by their curiosity and creativity, thriving in
          environments where they can explore new ideas and connect with others.
          They are drawn to work that aligns with their values and passions,
          although they may struggle with maintaining consistency in the long
          run.
        </p>
        <p>
          They need careers that allow for personal growth and meaningful
          impact, but they may find it difficult to settle into a routine. ENFPs
          excel in roles that involve leadership, creativity, and interpersonal
          connection.
        </p>
      </>
    ),
  },
  ENTP: {
    mainImage: "/images/personalities/entp.svg",
    niceName: "Debater",
    fullCode: "ENTP-A",
    avatarSrc: "",
    avatarAlt: "ENTP avatar",
    avatarSrcStatic: "",
    snippet:
      "Debaters are smart and curious thinkers who cannot resist an intellectual challenge.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "prospecting",
        label: "Tactics",
        color: "purple",
        score: 22,
        pct: 61,
        trait: "Prospecting",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Prospecting individuals are very good at improvising and adapting to opportunities. They tend to be flexible nonconformists who value novelty above stability.",
        snippet:
          "You’re likely very good at improvising and adapting. You tend to be flexible and value novelty above stability.",
        imageAlt:
          "A scene showing a couple buying a bunch of items that are on sale.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_prospecting.svg",
      },
    ],
    personality: (
      <p>
        Quick-witted and audacious, ENTPs thrive on spirited debates and
        challenging the status quo. They are curious, knowledgeable, and
        playful, always questioning assumptions and exploring new ideas with
        relentless enthusiasm.
      </p>
    ),
    strengths: [
      {
        title: "Knowledgeable",
        description:
          "ENTPs eagerly absorb new information, particularly abstract concepts, driven by curiosity rather than necessity.",
      },
      {
        title: "Quick Thinkers",
        description:
          "Their flexible minds allow them to shift effortlessly between ideas, making them formidable in discussions and problem-solving.",
      },
      {
        title: "Original",
        description:
          "Unbound by tradition, they synthesize unique ideas and solutions by connecting disparate concepts.",
      },
      {
        title: "Excellent Brainstormers",
        description:
          "They analyze problems from multiple angles, rapidly discarding ineffective solutions in favor of novel possibilities.",
      },
      {
        title: "Charismatic",
        description:
          "ENTPs’ confidence and wit make them engaging communicators who effortlessly charm and entertain.",
      },
      {
        title: "Energetic",
        description:
          "When tackling interesting problems, they exhibit boundless enthusiasm and persistence.",
      },
    ],
    weaknesses: [
      {
        title: "Very Argumentative",
        description:
          "ENTPs relish debates, sometimes creating tension by relentlessly challenging others’ beliefs.",
      },
      {
        title: "Insensitive",
        description:
          "Their rational nature often leads them to dismiss emotions, unintentionally pushing conversations past acceptable limits.",
      },
      {
        title: "Intolerant",
        description:
          "They may dismiss people whose ideas cannot withstand intense scrutiny, sometimes prematurely.",
      },
      {
        title: "Difficulty Focusing",
        description:
          "Their excitement for new ideas can cause them to abandon previous plans before completion.",
      },
      {
        title: "Dislike Practical Matters",
        description:
          "ENTPs struggle with structure and organization, preferring theoretical discussions over execution.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ENTPs bring passion and intellectual stimulation to their
          relationships, seeking partners who match their curiosity and energy.
          Their love for deep discussions and novel experiences keeps
          relationships exciting but can also be overwhelming.
        </p>
        <p>
          While they thrive on debate, their natural impatience and need for
          novelty can challenge stability. Learning to balance intellectual
          engagement with emotional sensitivity helps them maintain strong,
          fulfilling connections.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ENTPs seek friends who can keep up with their rapid-fire debates and
          intellectual curiosity. They enjoy playful arguments and discussions,
          valuing companions who challenge and engage them.
        </p>
        <p>
          While they excel at communication, they may struggle with emotional
          support, preferring rational solutions over sympathy. Building deeper
          friendships requires recognizing the value of emotional connection
          alongside intellectual stimulation.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ENTPs encourage independent thinking and curiosity in
          their children, fostering a love for learning and exploration. They
          prefer open discussions over strict rules, aiming to raise critical
          thinkers.
        </p>
        <p>
          However, their spontaneous nature may make consistency a challenge.
          Balancing freedom with structure helps them provide stability while
          nurturing their child’s intellectual growth.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ENTPs thrive in careers that allow creativity, problem-solving, and
          innovation. They excel in brainstorming and challenging conventional
          approaches, making them natural entrepreneurs and strategists.
        </p>
        <p>
          However, they may struggle with repetitive tasks and structured
          environments. Finding roles that offer variety and intellectual
          stimulation is key to their professional fulfillment.
        </p>
      </>
    ),
  },
  ESTJ: {
    mainImage: "/images/personalities/estj.svg",
    niceName: "Executive",
    fullCode: "ESTJ-A",
    avatarSrc: "",
    avatarAlt: "ESTJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Executives are excellent administrators, unsurpassed at managing things – or people.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        ESTJs are the embodiment of tradition, order, and responsibility. They
        are natural leaders who value honesty, dedication, and efficiency,
        working tirelessly to bring structure and organization to their
        communities. With a strong sense of duty, they uphold laws and
        principles, ensuring that tasks are completed with precision and
        reliability.
      </p>
    ),
    strengths: [
      {
        title: "Dedicated",
        description:
          "ESTJs see commitments through to completion, refusing to abandon responsibilities even when they become difficult or tedious.",
      },
      {
        title: "Strong-willed",
        description:
          "They stand firmly by their principles and ideas, requiring clear and undeniable proof before changing their stance.",
      },
      {
        title: "Direct and Honest",
        description:
          "ESTJs value straightforward facts and communication, ensuring clarity in all interactions.",
      },
      {
        title: "Loyal and Reliable",
        description:
          "They uphold commitments and responsibilities, making them trustworthy friends, employees, and family members.",
      },
      {
        title: "Enjoy Creating Order",
        description:
          "ESTJs thrive in structured environments and work to establish rules and systems that foster efficiency and stability.",
      },
      {
        title: "Excellent Organizers",
        description:
          "With a commitment to structure and clear standards, they excel in leadership and administrative roles.",
      },
    ],
    weaknesses: [
      {
        title: "Inflexible and Stubborn",
        description:
          "ESTJs can struggle to accept new ideas and alternative methods until they are thoroughly proven.",
      },
      {
        title: "Uncomfortable with Change",
        description:
          "They often prefer tradition over innovation, resisting untested solutions and unfamiliar approaches.",
      },
      {
        title: "Judgmental",
        description:
          "They hold strong convictions about right and wrong, sometimes overlooking different perspectives and approaches.",
      },
      {
        title: "Too Focused on Social Status",
        description:
          "ESTJs take pride in their reputation and can sometimes prioritize societal expectations over personal fulfillment.",
      },
      {
        title: "Difficulty Relaxing",
        description:
          "Their need for control and dignity makes it hard for them to unwind and enjoy spontaneity.",
      },
      {
        title: "Difficulty Expressing Emotion",
        description:
          "They prioritize logic and efficiency, sometimes neglecting the emotional needs of others.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ESTJs are steadfast and committed partners who value honesty and
          stability in relationships. They make their expectations clear from
          the start and remain consistent in their goals and commitments. While
          not the most spontaneous, they ensure a secure and structured
          relationship built on trust and shared values.
        </p>
        <p>
          However, their straightforward and pragmatic nature can sometimes make
          emotional intimacy challenging. They may struggle with subtle
          emotional cues and need to work on recognizing and validating their
          partner’s emotional needs. Balancing rationality with emotional
          connection can lead to stronger, more fulfilling relationships.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ESTJs build friendships based on shared values, activities, and
          traditions. They are loyal and dependable friends who enjoy structured
          social interactions, often taking the lead in organizing gatherings
          and events. Their friendships thrive in routine and mutual respect.
        </p>
        <p>
          However, their strong adherence to tradition and structure can make it
          difficult for them to connect with those who have vastly different
          worldviews. While they are engaging and enthusiastic, they may need to
          make an effort to appreciate diverse perspectives and build
          connections outside their usual circles.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ESTJs instill strong values of discipline, responsibility,
          and respect in their children. They set clear expectations and enforce
          rules to create a structured and stable home environment. Hard work
          and tradition are central to their parenting style.
        </p>
        <p>
          However, their strict approach can sometimes lead to challenges as
          children grow and seek independence. ESTJs may need to balance
          structure with flexibility, allowing their children to explore
          individuality while maintaining the core family values they hold dear.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ESTJs thrive in careers that require leadership, organization, and
          clear decision-making. They excel in managerial roles, administration,
          and law enforcement, where their ability to enforce structure and
          uphold standards is highly valued.
        </p>
        <p>
          Their dedication and efficiency make them excellent team leaders, but
          they may struggle with adapting to unconventional or rapidly changing
          environments. They perform best in careers with clear hierarchies,
          defined roles, and structured processes that align with their
          preference for order and tradition.
        </p>
      </>
    ),
  },
  ESFJ: {
    mainImage: "/images/personalities/esfj.svg",
    niceName: "Consul",
    fullCode: "ESFJ-A",
    avatarSrc: "",
    avatarAlt: "ESFJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Consuls are extraordinarily caring, social, and popular people, always eager to help.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "sensing",
        label: "Information",
        color: "green",
        score: 25,
        pct: 62,
        trait: "Sensing",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Sensing individuals focus on facts, details, and what can be directly observed or experienced.",
        snippet: "You’re detail-oriented and focus on tangible facts.",
        imageAlt: "A person carefully examining a map.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_sensing.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        ESFJs thrive in social settings, valuing hospitality, good manners, and
        a sense of duty. They build strong connections with those who share
        their values and often take on the role of holding families and
        communities together.
      </p>
    ),
    strengths: [
      {
        title: "Strong Practical Skills",
        description:
          "ESFJs excel at managing daily tasks and ensuring the well-being of those close to them.",
      },
      {
        title: "Strong Sense of Duty",
        description:
          "They are responsible and hardworking, committed to fulfilling their obligations in personal and professional settings.",
      },
      {
        title: "Very Loyal",
        description:
          "ESFJs value stability and can always be counted on to support their loved ones.",
      },
      {
        title: "Sensitive and Warm",
        description:
          "They seek harmony, care about others’ feelings, and strive to create win-win situations.",
      },
      {
        title: "Good at Connecting with Others",
        description:
          "ESFJs are confident, social, and well-liked, with a strong need for belonging.",
      },
    ],
    weaknesses: [
      {
        title: "Worried about Their Social Status",
        description:
          "They can become overly concerned with status, affecting their creativity and openness.",
      },
      {
        title: "Inflexible",
        description:
          "ESFJs value tradition and may struggle to accept unconventional ideas or changes.",
      },
      {
        title: "Vulnerable to Criticism",
        description:
          "They dislike conflict and may become defensive when their beliefs are challenged.",
      },
      {
        title: "Often Too Needy",
        description:
          "ESFJs seek constant appreciation and may fish for compliments to feel valued.",
      },
      {
        title: "Too Selfless",
        description:
          "They may overextend themselves for others while neglecting their own needs.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ESFJs value committed relationships and seek long-term stability. They
          take dating and marriage seriously, building mutual respect and
          understanding with their partners.
        </p>
        <p>
          They need affirmation and struggle with criticism, sometimes
          compromising their values for approval. However, when in a secure
          relationship, they are dedicated and responsible partners.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          ESFJs are social and loyal, maintaining large circles of friends with
          dedication and warmth. They enjoy making people happy and expect
          mutual support in return.
        </p>
        <p>
          They can be overly protective and sometimes struggle with differing
          opinions. Expanding their perspective can help them grow and
          strengthen friendships.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          ESFJs are affectionate and dedicated parents who create a stable,
          loving environment. They cherish their role and enjoy providing
          guidance and care to their children.
        </p>
        <p>
          Their strong sense of responsibility ensures their children feel
          secure, though they may become overprotective. Allowing independence
          can help their children grow.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ESFJs excel in careers that involve helping and organizing others.
          They thrive in structured environments and enjoy roles where they can
          support their team.
        </p>
        <p>
          They may struggle in workplaces that lack clear guidelines or
          appreciation. Their strong sense of duty and work ethic make them
          reliable and valued employees.
        </p>
      </>
    ),
  },
  ENFJ: {
    mainImage: "/images/personalities/enfj.svg",
    niceName: "Protagonist",
    fullCode: "ENFJ-A",
    avatarSrc: "",
    avatarAlt: "ENFJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Protagonists are charismatic and inspiring leaders, able to mesmerize their listeners.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "feeling",
        label: "Decision-Making",
        color: "pink",
        score: 18,
        pct: 45,
        trait: "Feeling",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Feeling individuals value empathy and emotional connections, prioritizing harmony and personal values in their decisions.",
        snippet: "You prioritize empathy and emotional connections.",
        imageAlt: "A person showing care and compassion towards others.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_feeling.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        ENFJs are natural leaders driven by a deep sense of purpose. They strive
        to make a positive impact on the world, using their charisma and passion
        to inspire those around them. With a strong moral compass, they always
        seek to do the right thing, even in difficult situations.
      </p>
    ),
    strengths: [
      {
        title: "Receptive",
        description:
          "ENFJs are open-minded and value diverse perspectives, allowing others to express themselves freely, even when they disagree.",
      },
      {
        title: "Reliable",
        description:
          "They are deeply committed to their responsibilities and will go to great lengths to uphold their promises and support others.",
      },
      {
        title: "Passionate",
        description:
          "ENFJs bring energy and enthusiasm to everything they do, whether in their careers, hobbies, or relationships.",
      },
      {
        title: "Altruistic",
        description:
          "They are driven by a genuine desire to help others, advocating for fairness and supporting those in need.",
      },
      {
        title: "Charismatic",
        description:
          "With their natural charm and strong communication skills, ENFJs easily connect with people and inspire collective action.",
      },
    ],
    weaknesses: [
      {
        title: "Unrealistic",
        description:
          "ENFJs often try to fix every problem they encounter, which can lead to burnout when they take on too much.",
      },
      {
        title: "Overly Idealistic",
        description:
          "They expect others to share their strong sense of right and wrong, which can leave them disappointed when people act differently.",
      },
      {
        title: "Condescending",
        description:
          "Their eagerness to guide and teach can sometimes come across as patronizing, making it difficult to persuade others.",
      },
      {
        title: "Intense",
        description:
          "ENFJs are deeply invested in self-improvement and may push others toward change, even when they aren't ready.",
      },
      {
        title: "Overly Empathetic",
        description:
          "They absorb others' problems as their own, which can leave them emotionally drained and overwhelmed.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ENFJs approach relationships with intensity and high ideals, believing
          in true love and deep emotional connections. They are expressive and
          passionate, never hesitating to make the first move or show their
          feelings openly.
        </p>
        <p>
          They thrive on supporting their partners&apos; dreams and ambitions,
          sometimes to the point of over-involvement. While their dedication
          fosters strong bonds, they must be mindful not to push their partners
          too hard toward self-improvement.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          Friendships are essential to ENFJs, who invest deeply in their
          relationships and seek to understand their friends on a profound
          level. They enjoy meeting people from all walks of life, always eager
          to engage in meaningful conversations.
        </p>
        <p>
          While they value diverse perspectives, they gravitate toward friends
          who share their commitment to doing the right thing. Their natural
          empathy makes them supportive and dependable, but they must be careful
          not to neglect their own needs while helping others.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ENFJs are nurturing and deeply involved in their
          children&apos;s lives, always striving to guide them toward becoming
          kind and principled individuals. They encourage open communication and
          emotional expression, fostering strong family bonds.
        </p>
        <p>
          However, their high expectations and desire to help may sometimes lead
          them to push their children too hard. Learning to balance guidance
          with allowing independence is key to their parenting success.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ENFJs excel in careers where they can inspire and uplift others, such
          as teaching, counseling, politics, and leadership roles. They are
          natural mentors, using their communication skills to guide teams and
          bring people together.
        </p>
        <p>
          Their strong sense of justice and dedication to ethical work make them
          stand out in their fields. However, they should be cautious not to
          overextend themselves, ensuring they maintain a healthy balance
          between their work and personal well-being.
        </p>
      </>
    ),
  },
  ENTJ: {
    mainImage: "/images/personalities/entj.svg",
    niceName: "Commander",
    fullCode: "ENTJ-A",
    avatarSrc: "",
    avatarAlt: "ENTJ avatar",
    avatarSrcStatic: "",
    snippet:
      "Commanders are bold, imaginative, and strong-willed leaders, always finding a way – or making one.",
    scales: ["Energy", "Mind", "Nature", "Tactics", "Identity"],
    traits: [
      {
        key: "extraversion",
        label: "Energy",
        color: "orange",
        score: 22,
        pct: 55,
        trait: "Extraversion",
        link: "https://www.16personalities.com/articles/energy-introversion-vs-extraversion",
        titles: [null],
        reverse: false,
        description:
          "Extraverted individuals are outgoing, energetic, and thrive in social settings. They draw energy from being around people.",
        snippet:
          "You’re outgoing and gain energy from interacting with others.",
        imageAlt: "A person enjoying a lively social event.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_extraversion.svg",
      },
      {
        key: "intuition",
        label: "Information",
        color: "yellow",
        score: 20,
        pct: 50,
        trait: "Intuition",
        link: "https://www.16personalities.com/articles/information-sensing-vs-intuition",
        titles: [null],
        reverse: false,
        description:
          "Intuitive individuals rely on abstract thinking, patterns, and possibilities rather than concrete facts.",
        snippet: "You’re imaginative and focus on possibilities and ideas.",
        imageAlt: "A person envisioning creative ideas.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_intuition.svg",
      },
      {
        key: "thinking",
        label: "Decision-Making",
        color: "red",
        score: 24,
        pct: 60,
        trait: "Thinking",
        link: "https://www.16personalities.com/articles/decision-making-thinking-vs-feeling",
        titles: [null],
        reverse: false,
        description:
          "Thinking individuals prioritize logic and objectivity when making decisions, often focusing on fairness and consistency.",
        snippet: "You prefer logic and objectivity in decision-making.",
        imageAlt: "A person analyzing data on a screen.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_thinking.svg",
      },
      {
        key: "judging",
        label: "Tactics",
        color: "purple",
        score: 28,
        pct: 70,
        trait: "Judging",
        link: "https://www.16personalities.com/articles/tactics-judging-vs-prospecting",
        titles: [null],
        reverse: false,
        description:
          "Judging individuals prefer structure, organization, and sticking to plans.",
        snippet: "You prefer a structured and organized approach.",
        imageAlt: "A person planning their day on a calendar.",
        imageSrc:
          "https://www.16personalities.com/static/images/theory/traits/16personalities_trait_judging.svg",
      },
    ],
    personality: (
      <p>
        People with the ENTJ personality type (Commanders) are natural-born
        leaders, driven by charisma, confidence, and a sharp mind. They are
        highly strategic thinkers with a strong sense of self-discipline,
        focusing on achieving their goals with precision. While their intensity
        and rationality may sometimes be off-putting, ENTJs are deeply committed
        to their work and take pride in their leadership abilities.
      </p>
    ),
    strengths: [
      {
        title: "Efficient",
        description:
          "ENTJs are highly efficient, identifying and eliminating inefficiencies wherever they go. They value productivity and ensure all resources are focused on achieving their goals.",
      },
      {
        title: "Energetic",
        description:
          "ENTJs are full of energy and thrive on complex challenges. They feel invigorated by accomplishing tasks and inspire others around them to be productive.",
      },
      {
        title: "Self-Confident",
        description:
          "ENTJs are confident in their abilities, trusting in their leadership skills and their capacity to face challenges head-on.",
      },
      {
        title: "Strong-Willed",
        description:
          "Once an ENTJ sets their mind to something, they relentlessly pursue it, exhibiting determination and willpower to overcome obstacles.",
      },
      {
        title: "Strategic Thinkers",
        description:
          "ENTJs excel at seeing the bigger picture, developing long-term strategies while addressing immediate challenges with calculated precision.",
      },
      {
        title: "Charismatic and Inspiring",
        description:
          "ENTJs have a natural charisma that draws others in, inspiring and motivating people to work toward common goals.",
      },
    ],
    weaknesses: [
      {
        title: "Stubborn and Dominant",
        description:
          "ENTJs can be too headstrong and dominant, often insisting on their perspective without considering others' input.",
      },
      {
        title: "Intolerant",
        description:
          "ENTJs can be dismissive of ideas that don't align with their goals, especially when those ideas are based on emotional reasoning.",
      },
      {
        title: "Impatient",
        description:
          "ENTJs tend to be impatient, especially with people who take too long to think or act, often misinterpreting hesitation as inefficiency.",
      },
      {
        title: "Arrogant",
        description:
          "ENTJs' confidence can sometimes come across as arrogance, making it difficult for others to feel heard or valued.",
      },
      {
        title: "Poor Handling of Emotions",
        description:
          "ENTJs are often emotionally distant, which can lead to conflicts with those who seek emotional support and understanding.",
      },
      {
        title: "Cold and Ruthless",
        description:
          "In their pursuit of efficiency and success, ENTJs can appear cold and ruthless, prioritizing goals over personal sensitivities.",
      },
    ],
    romanticRelationships: (
      <>
        <p>
          ENTJs approach romantic relationships with a clear sense of purpose,
          taking charge to ensure the relationship is rewarding. They are deeply
          committed but may end relationships abruptly if they see no future,
          which can come off as ruthless.
        </p>
        <p>
          However, when the relationship is on a positive track, ENTJs bring
          energy, creativity, and a drive for mutual growth. They push their
          partner to succeed, but must also work on emotional sensitivity to
          ensure lasting happiness.
        </p>
      </>
    ),
    friendship: (
      <>
        <p>
          In friendships, ENTJs value intelligence and deep discussions. They
          expect their friends to engage in thoughtful debates and personal
          growth, which makes them highly demanding but also very loyal and
          dedicated to their friends.
        </p>
        <p>
          While their dominance and impatience can sometimes alienate more
          sensitive friends, ENTJs are open to learning from others&apos;
          emotional perspectives and can form strong, meaningful bonds with
          like-minded individuals.
        </p>
      </>
    ),
    parenthood: (
      <>
        <p>
          As parents, ENTJs aim to nurture their children&apos;s talents and
          intellectual growth, with a strong focus on independence and rational
          thinking. They may struggle with emotional expression but strive to
          maintain discipline and structure in the household.
        </p>
        <p>
          ENTJ parents must work on emotional sensitivity, especially with
          younger children, and learn to balance their strict approach with a
          more flexible, empathetic attitude to foster a healthy, supportive
          relationship.
        </p>
      </>
    ),
    careerPath: (
      <>
        <p>
          ENTJs excel in careers that require leadership, strategic thinking,
          and a drive for success. Their ambition and determination make them
          well-suited for entrepreneurial roles or high-level positions where
          they can guide others toward achieving a common vision.
        </p>
        <p>
          In business, ENTJs thrive by taking charge and pushing projects
          forward. Their self-confidence and clear vision make them strong
          leaders, and they are drawn to roles that allow them to shape the
          future with their innovative ideas.
        </p>
      </>
    ),
  },
};
