export const questions = [
  "I enjoy spending time in large groups of people.", // E vs I
  // 3 , 2, 1, 0, -1, -2, -3
  "I often reflect on how things could be different in the future.", // N vs S
  "I make decisions based on logic rather than emotions.", // T vs F
  "I like to have a clear plan before starting something new.", // J vs P
  "I feel energized by spending time with other people.",
  "I prefer concrete facts over abstract ideas.",
  "I find it easy to stay detached from my emotions.",
  "I thrive in structured environments.",
  "I often initiate conversations in social settings.",
  "I enjoy imagining possible outcomes for the future.",
  "I prioritize efficiency over personal values when making decisions.",
  "I prefer flexibility over rigid plans.",
  "I tend to think out loud.",
  "I focus more on the present moment than future possibilities.",
  "I believe logical reasoning should guide most decisions.",
  "I feel comfortable adapting to unexpected changes.",
  "I am energized by spending time alone.",
  "I often think about the deeper meaning behind things.",
  "I take others’ feelings into account when making decisions.",
  "I prefer having a schedule to follow.",
  "I enjoy meeting new people and making new friends.",
  "I prefer practical applications over theoretical concepts.",
  "I make decisions based on how I feel in the moment.",
  "I often go with the flow rather than making plans.",
  "I find social interactions draining.",
  "I like exploring abstract ideas and concepts.",
  "I think fairness and consistency are more important than empathy.",
  "I feel stressed when things are unorganized.",
  "I enjoy participating in group activities.",
  "I focus on what is happening now rather than speculating about the future.",
  "I trust my head over my heart in decision-making.",
  "I am comfortable with sudden changes in plans.",
  "I find it hard to approach new people.",
  "I am drawn to big-picture thinking and innovative ideas.",
  "I consider how my decisions will affect others’ emotions.",
  "I value predictability and organization.",
  "I seek out opportunities for social interaction.",
  "I prefer step-by-step instructions to ambiguous guidelines.",
  "I weigh pros and cons objectively when making choices.",
  "I enjoy experimenting with new approaches and methods.",
  "I often avoid crowded social gatherings.",
  "I love exploring possibilities beyond the obvious.",
  "I tend to prioritize compassion over logic.",
  "I like knowing what to expect each day.",
  "I find energy in collaborating with others.",
  "I rely on past experiences rather than future predictions.",
  "I think of decisions as puzzles to solve logically.",
  "I am spontaneous and open to change.",
  "I enjoy solitude and quiet reflection.",
  "I often think outside the box.",
  "I empathize deeply with others’ emotions.",
  "I like keeping my environment tidy and organized.",
  "I enjoy being the center of attention.",
  "I prefer realistic goals over ambitious dreams.",
  "I find it difficult to separate emotions from decision-making.",
  "I enjoy tackling challenges in an unstructured way.",
  "I find it easy to strike up a conversation with strangers.",
  "I am more interested in exploring theories than focusing on practical details.",
  "I tend to prioritize emotional well-being over achieving specific goals.",
  "I enjoy having clear deadlines and structured tasks to work on.",
];
